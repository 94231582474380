import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SuccessPage = () => (
  <Layout>
    <SEO title="Submission Successful" />

    <section>
			<div className="container max-width-adaptive-sm margin-y-lg">
				<div className="text-component text-md">
					<h1 className="text-xl">Thank you!</h1>
					<p>Thanks for contacting me. I will get back to you shortly!</p>
					<p className="text-sm">
						<span role="img" aria-hidden="true">👈</span> <Link to="/">Return home</Link>
					</p>
				</div>
			</div>
		</section>

  </Layout>
)

export default SuccessPage
